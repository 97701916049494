<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7 mb-4">
            <b-button size="md" text="Button" variant="primary" @click="onDialogMerge()">รวม</b-button>
          </div>
          <div class="col-sm-5">
            <b-input-group>
              <b-input-group-append>
                <b-input-group-text class="input_border_radius_left_top_bt"><b-icon icon="search" /></b-input-group-text>
              </b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา" v-model="criteria.search" class="form-control-group" @keyup.enter.native="searchHandler()" ></b-form-input>
              <b-input-group-append>
                <b-button size="md" text="Button" variant="primary" @click="searchHandler()" class="btn_border_radius_right_top_bt" style="width: 86px;">ค้นหา</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive ">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center">
                  <th style="min-width: 50px; width: 1px;">เลือก</th>
                  <th class="cursor-pointer" @click="sortHandler('name')">
                    ชื่อบริษัท
                    <div class="float-right" v-if="criteria.orderBy=='name'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th class="cursor-pointer" @click="sortHandler('store_id')">
                    ร้านค้า
                    <div class="float-right" v-if="criteria.orderBy=='store_id'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th class="cursor-pointer" @click="sortHandler('contact')">
                    ชื่อผู้ติดต่อ
                    <div class="float-right" v-if="criteria.orderBy=='contact'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i>
                    </div>
                  </th>
                 
                  <th class="cursor-pointer" @click="sortHandler('phone')">
                    เบอร์โทรศัพท์
                    <div class="float-right" v-if="criteria.orderBy=='phone'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"
                      ></i>
                    </div>
                  </th>
                  <th class="cursor-pointer" @click="sortHandler('email')">
                    อีเมล
                    <div class="float-right" v-if="criteria.orderBy=='email'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th class="cursor-pointer" @click="sortHandler('line')">
                    ไลน์
                    <div class="float-right" v-if="criteria.orderBy=='line'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th class="cursor-pointer" @click="sortHandler('remark')">
                    หมายเหตุ
                    <div class="float-right" v-if="criteria.orderBy=='remark'">
                      <i class="fa" :class="criteria.ascDesc=='desc'?' fa-caret-up':' fa-caret-down'"></i>
                    </div>
                  </th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items" :key="`main-list-${data.id}-${index}`" :id="`checkbox_customer_index${index}`" class="main-list">
                  <td class="text-center checkbox_style">
                    <b-form-checkbox :name="`checkbox_group${data.id}`" v-model="data.selected" @change="selectedCustomer(...arguments,data, index)" />
                  </td>
                  <td>{{data.name}}</td>
                  <td>{{data.store_id}}</td>
                  <td>{{data.contact}}</td>
                  <td>{{data.phone}}</td>
                  <td>{{data.email}}</td>
                  <td>{{data.line}}</td>
                  <td>{{data.remark}}</td>
                  <td class="text-center">
                    <span class="style_icon_btn" v-if="permission.actionData.flag_update">
                      <i class="fas fa-pen" @click="onEditHandler(data.id)" title="แก้ไข"></i>
                    </span>
                    <span class="style_icon_btn">
                      <i
                        class="fas fa-history"
                        @click="onHistoryHandler(data)"
                        title="ประวัติการสั่งซื้อ"
                      ></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td colspan="8">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
          <select
            class="form-control mb-2"
            id="pageLimit"
            style="width: 80px;"
            v-model="paging.limit"
            v-on:change="changeLimit()"
          >
            <option v-for="(data) in paging.pages" :key="data" :value="data">{{data}}</option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3" v-if="paging.rowTotal > 0">
            <div class="col-xl-11 col-lg-10 mb-2 text-right" style="margin-top: 30px;">
              <label
                class="form-label"
                style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;"
              >
                แสดงรายการที่
                <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก
                <strong>{{paging.rowTotal | numberIntFormat}} รายการ</strong>
              </label>
              <paginate
                v-model="paging.page"
                :first-last-button="true"
                :page-count="paging.pageTotal"
                :page-range="5"
                :margin-pages="2"
                :prev-text="'&lt;'"
                :next-text="'&gt;'"
                :first-button-text="'&verbar;&lt;'"
                :last-button-text="'&gt;&verbar;'"
                :click-handler="changePage"
                :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                :page-class="'page-link'"
                :prev-class="'page-link prev_paging'"
                :next-class="'page-link next_paging'"
              ></paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label
                class="form-label"
                style="font-size: 12px;
                      font-family: Sarabun-Light;"
              >Go to page</label>
              <Select2
                v-model="paging.page"
                :options="paging.pageSizes"
                @change="changePage(paging.page)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <dialog_customer_merge :item="selected" @successFuction="initialData" />
    </div>
  </div>
</template>
<script>
import config from "../../../util/config";
import dialog_customer_merge from "./dialog.customer.merge.vue";
import $ from "jquery";
export default {
  name: "customer-view-page-customer",
  data() {
    return {
      permission: {
        appSlug: "customer",
        actionData: {}
      },
      criteria: {
        search: "",
        orderBy: "",
        ascDesc: "",
        name_comp_filters: [],
        name_contr_filters: []
      },
      selected: [],
      items: [],
      paging: {
        page: 1,
        limit: config.pagination.limit,
        pages: config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      },
      page: 250
    };
  },
  props: [],
  methods: {
    onEditHandler(id) {
      this.SessionStorageUtils.setSession("page-customer", id);
      this.$router.push(`/customer/edit/${id}`);
    },

    /******* local function ******/
    changeLimit() {
      this.paging.page = 1;
      this.getListItems();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getListItems();
    },
    sortHandler(orderBy) {
      if (orderBy != this.criteria.orderBy) {
        this.criteria.ascDesc = "asc";
      } else {
        this.criteria.ascDesc = this.criteria.ascDesc == "asc" ? "desc" : "asc";
      }
      this.criteria.orderBy = orderBy;
      this.getListItems();
    },
    searchHandler() {
      this.paging.page = 1;
      this.getListItems();
    },
    onDialogMerge(){
      this.$bvModal.show("dialog_customer_merge");
    },
    async getListItems() {
      const params = `search=${this.criteria.search}&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
      const result = await this.HttpServices.getData(`/ab_customer?${params}`);
      if (result && result.status.code == "200") {
        const data = result.data;
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.rowTotal = data.rowTotal;
        this.paging.pageTotal = total;
        this.items = data.data;
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
      }
      $(`tr.main-list`).removeClass("highlight");
    },
    checkDisabledDelete() {
      let resp = true;
      for (const v of this.items) {
        if (v.selected) {
          resp = false;
          break;
        }
      }
      return resp;
    },
    selectNameContract(item) {
      this.searchHandler();
    },
    countFilter() {
      const filters = this.criteria.name_comp_filters.filter(v => v.selected);
      const filtersNameContr = this.criteria.name_contr_filters.filter(
        v => v.selected
      );
      return filters.length + filtersNameContr.length;
    },
    unSelectFilter(item) {
      item.selected = false;
      this.searchHandler();
    },
    unSelectAllFilter() {
      this.criteria.name_comp_filters.map(v => {
        v.selected = false;
      });
      this.criteria.name_contr_filters.map(v => {
        v.selected = false;
      });
      this.searchHandler();
    },
    async getFilterGroups() {
      let data = this.mock.search_filters;
      this.criteria.name_comp_filters = data.name_comp_filters;
      this.criteria.name_contr_filters = data.name_contr_filters;
    },
    onHistoryHandler(data) {
      this.SessionStorageUtils.setSession(
        "page-customer",
        JSON.stringify(data)
      );
      this.$router.push(`/customer/history_order/${data.id}`);
    },
    selectedCustomer(value, obj, idx) {
      let index = idx;

      if (!this.selected.includes(obj)) {
        $(`tr#checkbox_customer_index${index}`).addClass("highlight");
        this.selected.push(obj);
      } else {
        $(`tr#checkbox_customer_index${index}`).removeClass("highlight");
        const idx = this.selected.indexOf(obj);
        if (idx > -1) {
          this.selected.splice(idx, 1);
        }
      }


    }
    /******* local function ******/
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    await this.getListItems();
    this.SessionStorageUtils.deleteSession("page-customer");
    this.getFilterGroups();
  },
  components: {
    dialog_customer_merge: dialog_customer_merge
  }
};
</script>
<style scope>
.checkbox_style > .custom-control {
  left: 12px;
}
</style>
