<template>
  <b-modal id="dialog_customer_merge">
    <template #modal-title>
      <h3> รวม order</h3>
    </template>
    <div>
      ต้องการที่จะรวม order ดังต่อไปนี้ ?
      <ul>
        <template v-for="(data, index) in item">
          <li v-if="data.selected" :key="index">{{data.id + " : " +data.name + " ("+data.contact+")" }}</li>
        </template>
      </ul>
      <label>เลือกรหัสลูกค้าหลัก</label>
      <select class="form-control">
        <template v-for="data in item">
          <option :value="data.id">{{data.id + " : " +data.name + "" }}</option>
        </template>
      </select>
    </div>
    <template #modal-footer>
      <b-button variant="danger" @click="mergeHandler()">รวม</b-button>
      <b-button variant="secondary" @click="close()">ยกเลิก</b-button>
    </template>
  </b-modal>
</template>
<script>
  export default {
    data() {
      return {
        show: true
      }
    },
    props: [
      'item', 'successFuction'
    ],
    methods: {
      async mergeHandler () {
        const selected = [];
        for(const v of this.item){
          if(v.selected){
            selected.push(v.id);
          }
        }
        if (selected <2) {
          this.AlertUtils.alert('warning', ``);
          this.$bvModal.hide('dialog_customer_merge');
          return;
        }
        const result = await this.HttpServices.postData("/ab_customer/merges", {id: selected});
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_order_remove');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      async removeHandler () {
        const selected = [];
        for(const v of this.item){
          if(v.selected){
            selected.push(v.id);
          }
        }
        if (selected === 0) {
          this.AlertUtils.alert('warning', `กรุณาเลือกข้อมูลที่ต้องการลบ`);
          this.$bvModal.hide('dialog_customer_merge');
          return;
        }
        const result = await this.HttpServices.postData("/ab_order/delete", {id: selected});
        if(result&&result.status.code=="200"){
          this.AlertUtils.alert('success', `บันทึกสำเร็จ`);
          this.$bvModal.hide('dialog_customer_merge');
          this.$emit("successFuction", "");
        }else{
          this.AlertUtils.alert('warning', result.status.message);
        }
      },
      close () {
        this.$bvModal.hide('dialog_customer_merge');
      }
    }
  }
</script>
